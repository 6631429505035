import SubstanceProvenanceDetailPanel from 'components/DetailPanels/SubstanceProvenanceDetailPanel';
import BaseTable from 'components/Tables/BaseTable';
import { actions as productActions } from 'ducks/products';
import { actions as productRMActions } from 'ducks/productsRM';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getImmutableStoreById } from 'selectors/generic';
import theme from 'source/theme';
import { MIXTURE, POLIMER, SUBSTANCE } from 'utils/constants/coreConstants';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/product/productSummary';
import noIcon from 'utils/tables/noIcon';

const iconSelector = (inOutFinalRM, setInOutFinalRM) => {
  const addToCargo = [
    {
      icon: tableIcons.AddToCargo(),
      tooltip: 'Carga',
      onClick: () => setInOutFinalRM('in'),
      position: 'toolbar'
    },
    {
      icon: tableIcons.AddToCargo(),
      tooltip: 'Carga',
      onClick: () => setInOutFinalRM('in'),
      position: 'toolbarOnSelect'
    }
  ];

  const formulation = [
    {
      icon: tableIcons.Formulation(),
      tooltip: 'Reacção',
      onClick: () => setInOutFinalRM('out'),
      position: 'toolbar'
    },
    {
      icon: tableIcons.Formulation(),
      tooltip: 'Reacção',
      onClick: () => setInOutFinalRM('out'),
      position: 'toolbarOnSelect'
    }
  ];

  const product = [
    {
      icon: tableIcons.FinalProduct(),
      tooltip: 'Produto final',
      onClick: () => setInOutFinalRM('final'),
      position: 'toolbar'
    },
    {
      icon: tableIcons.FinalProduct(),
      tooltip: 'Produto final',
      onClick: () => setInOutFinalRM('final'),
      position: 'toolbarOnSelect'
    }
  ];

  switch (inOutFinalRM) {
    case 'in':
      return [...formulation, ...product];
    case 'out':
      return [...addToCargo, ...product];
    case 'final':
      return [...addToCargo, ...formulation];
    case 'distr':
      return [...product];
    default:
      return [...formulation, ...product];
  }
};

const Component = props => {
  let {
    highlightSubstances, inOutFinalRM, productId, productsById, rawMaterialsById,
    user
  } = props;
  const {
    createProductRM, fetchProduct, handleCloseDrawer, setInOutFinalRM,
    setHighlightMP, setHighlightSubstances, updateProductRM
  } = props;
  const tableRef = React.useRef(null);

  const [loading, setLoading] = useState(false);


  const summaryRawMaterials = [];

  /**
   * Algorithm to filter:
   *  - Subtances from mixtures in CARGA
   *  - Substances in CARGA
   *  - Substances in MP FINAL
   */

  /**
   * First adding all substances related with mixtures, assuming that the substance
   * has not been modified in exclusions AND all substances that have cod_mp (CARGA)
   **/
  productsById[productId].Produto_Mps.forEach(prm => {

    if (prm.mp_formulacao) {

      if (rawMaterialsById[prm.id_mp.id].tipo === MIXTURE) {
        rawMaterialsById[prm.id_mp.id].inter_substancias.forEach(interSubstance => {
          const substanceId = interSubstance.id_substancia;
          summaryRawMaterials.push({
            ...rawMaterialsById[substanceId],
            mp_final: false,
            mp_formulacao: true,
            mp_excluido_final: false,
            id_produto_mp: null, // Ainda não tem id_produto_mp porque não se sabe se existe
            contribui_perigo: false,
            cod_mp: rawMaterialsById[prm.id_mp.id].cod_mp,
            mp_nome_mistura: rawMaterialsById[prm.id_mp.id].mp_nome,
            id_mistura_caminho_mp: prm.id_mp.id,
            id_mistura_caminho: interSubstance.id,
            // id_mistura_caminho: prm.id_mp.id,
            // id_produto_mp_mistura_caminho: prm.id
          });
        });
      } else if (!prm.id_mistura_caminho_mp) {
        summaryRawMaterials.push({
          ...rawMaterialsById[prm.id_mp.id],
          mp_final: prm.mp_final,
          mp_formulacao: prm.mp_formulacao,
          mp_excluido_final: prm.mp_excluido_final,
          id_produto_mp: prm.id,
          contribui_perigo: prm.contribui_perigo,
          mp_nome_mistura: '',
          id_mistura_caminho_mp: null,
          id_mistura_caminho: null
        });
      }
    }
  });


  /**
   * Finding Substances that overlap with the default/lazy ones created above
   * and putting these ones on top
   **/
  productsById[productId].Produto_Mps.forEach(prm => {
    // TODO removed prm.mp_formulacao from IF because is unclear how values come tagged from the API
    //if (prm.id_mistura_caminho_mp && prm.mp_formulacao) {
    if (prm.id_mistura_caminho_mp) {

      const index = summaryRawMaterials.findIndex(s =>
        s.id === prm.id_mp.id &&
        s.id_mistura_caminho_mp === prm.id_mistura_caminho_mp.id
      );
      if (index >= 0) {
        summaryRawMaterials[index] =
          {
            ...summaryRawMaterials[index],
            id_produto_mp: prm.id,
            mp_excluido_final: prm.mp_excluido_final,
            contribui_perigo: prm.contribui_perigo
          };
      }
    }
  });
  /**
   * Finding Substances with mp_final True (FINAL)
   * The above is incorrect, mp_final doesn't seem to make sense
   */
  productsById[productId].Produto_Mps.forEach(prm => {
    if (
      !prm.mp_formulacao &&
      !prm.id_mistura_caminho_mp &&
      (rawMaterialsById[prm.id_mp.id].tipo === SUBSTANCE || rawMaterialsById[prm.id_mp.id].tipo === POLIMER)
    ) {
      summaryRawMaterials.push({
        ...rawMaterialsById[prm.id_mp.id],
        mp_final: prm.mp_final,
        mp_formulacao: prm.mp_formulacao,
        mp_excluido_final: prm.mp_excluido_final,
        id_produto_mp: prm.id,
        contribui_perigo: prm.contribui_perigo,
        cod_mp: null,
        mp_nome_mistura: null
      });
    }
  });
  /**
   * Filter summaryRawMaterials to get summarySubstances unrepeated
   */
  const summarySubstances = [];
  summaryRawMaterials.forEach(rm => {
    if (summarySubstances.findIndex(substance => substance.id === rm.id) === -1) {
      summarySubstances.push(rm);
    }
  });

  return (
    <BaseTable
      ref={tableRef}
      titleBack={true}
      titleAction={handleCloseDrawer}
      title={`${productsById[productId].codigo_produto} - ${productsById[productId].produto_nome}`}
      //title={`PRODUTO FINAL`}
      columns={columns(user)}
      data={summarySubstances}
      options={{
        rowStyle: rowData => ({
          backgroundColor: highlightSubstances.includes(rowData.id) ?
            theme.palette.primary.light : ''
        })
      }}
      onRowClick={() => null}
      onRowDoubleClick={rowData => {
        setHighlightSubstances([rowData.id])
        setHighlightMP([rowData.id, ...rowData.inter_misturas.map(mistura => mistura.id_mistura)])
      }}
      isLoading={loading}
      actions={iconSelector(inOutFinalRM, setInOutFinalRM)}
      detailPanel={[
        // rowData => rowData.inter_misturas.length === 0 ?
        rowData => summaryRawMaterials.filter(obj => (obj.id === rowData.id) && (obj.id_mistura_caminho_mp || obj.mp_formulacao)).length > 0 ?
          {
            icon: tableIcons.Mixtures,
            tooltip: 'Proveniência',
            render: () => <SubstanceProvenanceDetailPanel rowData={rowData} data={summaryRawMaterials}/>
          } :
          noIcon
      ]}
      editable={{
        //isEditable: rowData => !rowData.mp_excluido_final,
        onRowUpdate: newData => {
          setLoading(true);
          const promises = [];
          const repeatedSubstances = summaryRawMaterials.filter(rm => rm.id === newData.id);
          repeatedSubstances.forEach(rs => {
            if (rs.id_produto_mp) {
              // TODO remove property id_produto_mp_mistura_caminho: rs.id_produto_mp_mistura_caminho after Nuno fixs API
              promises.push(updateProductRM(
                rs.id_produto_mp,
                {
                  contribui_perigo: newData.contribui_perigo,
                  mp_excluido_final: newData.mp_excluido_final,
                  //id_produto_mp_mistura_caminho: rs.id_produto_mp_mistura_caminho
                }
              ));
            } else {
              promises.push(createProductRM(
                {
                  id_produto: productId,
                  id_mp: rs.id,
                  mp_final: rs.mp_final,
                  mp_formulacao: rs.mp_formulacao,
                  contribui_perigo: newData.contribui_perigo,
                  mp_excluido_final: newData.mp_excluido_final,
                  id_mistura_caminho_mp: rs.id_mistura_caminho_mp,
                  id_mistura_caminho: rs.id_mistura_caminho,
                  //id_produto_mp_mistura_caminho: rs.id_produto_mp_mistura_caminho
                }
              ))
            }
          });
          return Promise.all(promises)
            .then(() => fetchProduct(productId))
            .catch(e => alert(e))
            .finally(() => setLoading(false));
        }
      }}
    />
  );
};

const makeMapStateToProps = () => {
  const rawMaterialsById = getImmutableStoreById('rawMaterials');
  const mapStateToProps = (state, props) => ({
    productsById: state.products.dataById,
    rawMaterialsById: rawMaterialsById(state),
    user: state.profile.entity
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  fetchProduct: id => dispatch(productActions.fetchOne(id)),
  updateProductRM: (id, data) => dispatch(productRMActions.updatePromise(id, data)),
  createProductRM: (data) => dispatch(productRMActions.createPromise(data))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
